import React,{useRef} from 'react';
import '../../../App.css';
import { Button } from '../../Button';
import './BannerSection.css';
import {Link} from 'react-router-dom';
import {FaAngleDown} from 'react-icons/fa';
function BannerSection() {
  const sectionloc = useRef(null);
  const gotoSection = () =>
      window.scrollTo({
          top: sectionloc.current.offsetTop,
          behavior: "smooth"
      });
  return (
    <div className='hero-container'>
      <video src='/videos/video-1.mp4' autoPlay loop muted  data-aos = 'fade-right'/>
      <h1>DAYAL ZIPPERS</h1>
      <p>Welcomes You</p>
      <div className='hero-btns'>
      <Link to = '/about'>
        <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
        > 
          KNOW US MORE
        </Button> 
        </Link>
        <Link to = '/contact'>
        <Button
          className='btns'
          buttonStyle='btn--primary'
          buttonSize='btn--large'
          onClick={console.log('hey')}
        >
          CONNECT US <i className='far fa-play-circle' />
        </Button>
        </Link>
      </div>
      <div className="arrow bounce">
        <FaAngleDown className = "ArrowDown" onClick={gotoSection}/>
    </div>
    <h1 ref = {sectionloc}></h1>
    </div>
  );
}

export default BannerSection;