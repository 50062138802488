import React,{useRef} from 'react';
import '../../../App.css';
import './ProductBanner.css';
import {FaAngleDown} from 'react-icons/fa';

function BannerSection() {
  const sectionloc = useRef(null);
  const gotoSection = () =>
      window.scrollTo({
          top: sectionloc.current.offsetHeight,
          behavior: "smooth"
      });
  return (
    <>
    <div className='hero2-container' ref = {sectionloc} data-aos='fade-right'>
      <h1>Checkout Our Products</h1>
      <p>"Quality zippers to choose from a wide collection."</p>
      <div className="arrow bounce">
        <FaAngleDown className = "ArrowDown" onClick = {gotoSection}/>
    </div>
    </div>
     </>
  );
}

export default BannerSection;