import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import './ProductCategoryView.css';
const containerstyle = {
        backgroundColor:"#FAFAFA",
        width:"100%",
}
const useStyles = makeStyles((theme) => ({
    gridContainer: {
        padding: "50px",
        maxWidth:'100%',
    },
    root: {
    },
    media: {
        height: 200,
    },
    titlestyle:{
        textAlign:'center',
        fontSize:15,
        flexGrow:1
    }

}));

const ProductCategoryView = ({data, tiledata}) => {

    const classes = useStyles();
    return (
        <div className = 'container-fluid' style = {containerstyle} data-aos="fade-right">
        <Grid
            container
            spacing={3}
            className="gridContainer"
        >
            {data[tiledata].map((tile) => (
                <Grid item xs={12} sm={6} md={4}>
                    <Card className="card-style">
                        <CardActionArea>
                            <CardMedia
                                className={classes.media}
                                image={tile.imgpath}
                                title={tile.title}
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2" 
                                className = {classes.titlestyle}>
                                    {tile.title}
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            ))}
        </Grid>
        </div>
    );
}




export default ProductCategoryView;




