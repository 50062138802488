import React from 'react';
import './TwoBlocks.css';

const TwoBlocks = () => {
  const iframestyle = {
    width: "100%",
    height: "400px",
  }
  const specificstyle = {
    width:"100%"
  }
  return (
    <div>
      <div className="main-section" data-aos ="fade-right">
        <main className="containerr">
          <section className="carde">
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15077.793905004173!2d72.8624912!3d19.1318364!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4cd507287b2df3!2sDAYAL%20ZIPPERS!5e0!3m2!1sen!2sin!4v1623063569555!5m2!1sen!2sin" style = {iframestyle} allowfullscreen="" loading="lazy"></iframe>
            <div>
              <h1>Address Information</h1>
              <h3>Main Office </h3>
              <p>
              B-10/A, Hema Industrial Estate, Sarvodaya Nagar, Jogeshwari East, Mumbai, Maharashtra-400060
              </p>
              <h3>Production Unit</h3>
              <p>
              Building No C-16,Unit No-101, Parasnath Complex, Valpada, Dapode, Bhiwandi, Maharashtra-421302
              </p>
              <h3>Contact Details</h3>
              <p>
              Phone No:  9137766253 / 9821018228<br/>
              Email: sales.dayalzip@gmail.com
              </p>
            </div>
          </section>
         </main>
      </div>
      </div>
  );
}

export default TwoBlocks;