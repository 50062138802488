import React from 'react';
import './ContactUs.css';
import ContactForm from './ContactForm';
const ContactUs = () => {
    const specificstyle = {
        width: "100%",
        height: "450px"
    }
    const h1style = {
        color:'black',
        fontFamily:'Poppins'
    }
    return (
        <div>
            <div className="main-section1" data-aos="fade-right">
                <header class="main-header">
                    <h1 style={h1style}>Fill the Form Below</h1>
                </header>
                <main className="containerr">
                    <section className="carde">
                        <img src="images/handshake.jpeg" style={specificstyle} alt="" />
                        <div>
                        <ContactForm/>
                        </div>
                    </section>
                </main>
            </div>
        </div>
    )
}

export default ContactUs;
