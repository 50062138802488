import React from 'react';
// import HeroSection from '../../HeroSection';
import ContactUs from './ContactUs';
// import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour } from './Data';
// import Pricing from '../../Pricing';
import TwoBlocks from './TwoBlocks';
import ContactBanner from './ContactBanner';
// import AddressBlock from './AddressBlock';
function Contact() {
  return (
    <>
    <ContactBanner/>
    <TwoBlocks/>
    <ContactUs/>
      {/* <HeroSection {...homeObjOne} />
      <HeroSection {...homeObjThree} /> */}
    </>
  );
}

export default Contact;
