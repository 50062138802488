import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin
} from 'react-icons/fa';

function Footer() {
  return (
    <div className='footer-container' data-aos = 'fade-in'>   
      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>Site Links</h2>
            <Link to='/'>Home</Link>
            <Link to='/about'>About Us</Link>
            <Link to='/products'>Products</Link>
            <Link to='/contact'>Contact Us</Link>
          </div>
          <div className='footer-link-items'>
            <h2>About Us</h2>
            <Link to='/about'>Overview</Link>
            <Link to='/about'>Quality</Link>
            <Link to='/about'>Production Infrastructure</Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>Products</h2>
            <Link to='/products'>Nylon Zippers</Link>
            <Link to='/products'>Fiber Zippers</Link>
            <Link to='/products'>Metal Zippers</Link>
            <Link to='/products'>Invisible Zippers</Link>
            <Link to='/products'>LFC Zippers</Link>
            <Link to='/products'>Sliders</Link>
          </div>
          <div className='footer-link-items'>
            <h2>Contact Us</h2>
            <Link to='/contact'>Inquiry</Link>
            <Link to='/contact'>Contact</Link>
          </div>
        </div>
      </div>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link to='/' className='social-logo'>
              DAYAL ZIPPERS
            </Link>
          </div>
          <small className='website-rights'>DAYAL ZIPPERS © 2021</small>
          <div className='social-icons'>
            <Link
              className='social-icon-link'
              to=''
              target=''
              aria-label='Facebook'
            >
              <FaFacebook />
            </Link>
            <Link
              className='social-icon-link'
              to=''
              target=''
              aria-label='Instagram'
            >
              <FaInstagram />
            </Link>
            <Link
              className='social-icon-link'
              to={
                ''
              }
              target=''
              aria-label='Youtube'
            >
              <FaYoutube />
            </Link>
            <Link
              className='social-icon-link'
              to=''
              target=''
              aria-label='Twitter'
            >
              <FaTwitter />
            </Link>
            <Link
              className='social-icon-link'
              to=''
              target=''
              aria-label='LinkedIn'
            >
              <FaLinkedin />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
