export const homeObjOne = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Overview',
  headline: 'Know More About Us',
  description:
    'Dayal Zippers, established in 1996, is located at Mumbai in Maharashtra. Having first started as a trading company, over the next 25 years it has become one of the largest zipper manufacturers in Mumbai with over thousands of zippers and zipper rolls being manufactured daily. Currently it boasts of world class infrastructure and modern machinery which is able to manufacture quality products in the quickest possible time.',
  buttonLabel: 'Get Started',
  imgStart: '',
  img: 'images/aboutus2.jpg',
  alt: 'Credit Card',
  aosdata: 'fade-left'
};

export const homeObjTwo = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'INFRASTRUCTURE',
  headline: 'Our Production Strength',
  description:
    'Dayal Zippers has a daily capacity of manufacturing over thousands of zippers and zipper rolls. We manufacture a wide range of products that include nylon zippers, metallic zippers, fiber zippers as well as zipper rolls according to the customer’s requirements. We also deal in sliders and can provide the same based on the consumer needs.',
  buttonLabel: 'Learn More',
  imgStart: '',
  img: 'images/prod1.jpeg',
  alt: 'Vault',
  aosdata:'fade-left'
};

export const homeObjThree = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Quality Standards',
  headline: 'We Meet Your Expectations',
  description:
    "Dayal Zippers focuses on providing the best possible quality to its customers and has a dedicated team of technical experts and quality experts that conducts all tests on the products during each stage of production.",
  buttonLabel: 'Start Now',
  imgStart: 'start',
  img: 'images/quality.jpeg',
  alt: 'Vault',
  aosdata: 'fade-right'
};



