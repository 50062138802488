import React, { useState } from "react";
import './ContactForm.css';

const ContactForm = () => {
  const [status, setStatus] = useState("Submit");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");
    const { name, email, message } = e.target.elements;
    let details = {
      name: name.value,
      email: email.value,
      message: message.value,
    };
    let response = await fetch("https://dayalzippers.herokuapp.com/contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });
    setStatus("Submit");
    // let result = await response.json();
    // alert(result.status);
    setSubmitted(true);
    await new Promise(resolve => setTimeout(() => {resolve();},3000));
    setName("");
    setMsg("");
    setEmail("");
    setSubmitted(false);
  };
  return (
    <div className='wrap'>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} required />
        </div>
        <div>
          <label htmlFor="email">Email:</label>
          <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        </div>
        <div>
          <label htmlFor="message">Message:</label>
          <textarea id="message" value={msg} onChange={(e) => setMsg(e.target.value)} required />
        </div>
        {submitted && (
          <p>Thanks for Connecting with Us. We'll get back to you at earliest.</p>)}
        <button className="formbutton" type="submit">{status}</button>
      </form>
    </div>
  );
};

export default ContactForm;
