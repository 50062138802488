import React, { useState} from 'react';
import { Link, NavLink } from 'react-router-dom';
import './Navbar.css';
import { FaBars, FaTimes } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';

function Navbar() {
  const [click, setClick] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const [navlogo, setNavlogo] = useState(false);
  const [navlinks, setNavlinks] = useState(false);
  const [navimg, setNavImg] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
      setNavlinks(true);
      setNavlogo(true);
      setNavImg(true);
    }
    else {
      setNavbar(false);
      setNavlogo(false);
      setNavlinks(false);
      setNavImg(false);
    }
  }
  window.addEventListener('scroll', changeBackground);

  return (
    <>
      <IconContext.Provider value={navlogo?{ color: 'black' }:{color:'white'}}>
        <nav className={navbar ? 'navbar-active' : 'navbar'}>
          <Link to='/'>
            <img className='imgstyle'
              src={navimg?require("../images/dlogo-removebg-preview.png"): require("../images/image4.png")} alt="logo"
            />
          </Link>
          <div className='navbar-container container'>
            <Link to='/' className={navlogo ? 'navbar-logo nvlogobg':'navbar-logo'} onClick={closeMobileMenu}>
              DAYAL ZIPPERS
            </Link>
            <div className='menu-icon' onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </div>
            <ul className={click ? 'nav-menu active' : 'nav-menu'}>

              <li className='nav-item' >
                <NavLink exact to='/'
                  className={navlinks ? 'nav-links nvlinkbg':'nav-links'}
                  onClick={closeMobileMenu}
                  activeClassName = 'active'
                >
                  Home
                </NavLink>
              </li>
              <li className='nav-item' activeClassName = 'active'>
                <NavLink
                  to='/about'
                  className={navlinks ? 'nav-links nvlinkbg':'nav-links'}
                  onClick={closeMobileMenu}
                  activeClassName = 'active'
                >
                  About Us
                </NavLink>
              </li>
              <li className='nav-item' activeClassName = 'active'>
                <NavLink
                  to='/products'
                  className={navlinks ? 'nav-links nvlinkbg':'nav-links'}
                  onClick={closeMobileMenu}
                  activeClassName = 'active'
                >
                  Products
                </NavLink>
              </li>
              <li className='nav-item' activeClassName = 'active'>
                <NavLink
                  to='/contact'
                  className={navlinks ? 'nav-links nvlinkbg':'nav-links'}
                  onClick={closeMobileMenu}
                  activeClassName = 'active'
                >
                  Contact Us
                </NavLink>
              </li>
            </ul>
          </div>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
