import React from 'react';
import { homeObjOne, homeObjTwo, homeObjThree} from './Data';
import ImageBanner from './ImageBanner.js';
import AboutSection from './AboutSection';


function About() {

  return (
    <>
    <ImageBanner/>
    <AboutSection {...homeObjOne} />
    <AboutSection {...homeObjThree} />
    <AboutSection {...homeObjTwo} />

      {/* <ImageBanner />
      <HeroSection {...homeObjOne} /> */}
      {/* <Pricing /> */}

    </>
  );
}

export default About;
