import React from 'react';
import './AboutSection.css';

function AboutSection({
  lightBg,
  topLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  imgStart,
  aosdata
})
{
  return (
    <>
      <div
        className={lightBg ? 'about__hero-section' : 'about__hero-section darkBg1'}
       data-aos = {{aosdata}==='fade-left'?'fade-left':'fade-right'} 
       >
        <div className='container'>
          <div
            className='row about__hero-row'
            style={{
              display: 'flex',
              flexDirection: imgStart === 'start' ? 'row-reverse' : 'row'
            }}
          >
            <div className='col'>
              <div className='about__hero-text-wrapper'>
                <div className='top-line'>{topLine}</div>
                <h1 className={lightText ? 'heading' : 'heading dark'}>
                  {headline}
                </h1>
                <p
                  className={
                    lightTextDesc
                      ? 'about__hero-subtitle'
                      : 'about__hero-subtitle dark'
                  }
                >
                  {description}
                </p>
              </div>
            </div>
            <div className='col'>
              <div className='about__hero-img-wrapper'>
                <img src={img} alt={alt} className='about__hero-img' data-aos = 'zoom-out'/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutSection;
