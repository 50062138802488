import React from 'react';
import './App.css';
import Home from './components/pages/HomePage/Home';
import About from './components/pages/About/About';
import Products from './components/pages/Products/Products';
import Contact from './components/pages/Contact/Contact';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/pages/Footer.js/Footer';
import ScrollToTop from './ScrollToTop';
import AOS from "aos";
import "aos/dist/aos.css";
import DirectTopScroll from './DirectTopScroll';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-199191619-1'); // add your tracking id here.
ReactGA.pageview(window.location.pathname + window.location.search);

AOS.init(
  {offset: 120, // offset (in px) from the original trigger point
  delay: 0, // values from 0 to 3000, with step 50ms
  duration: 500, // values from 0 to 3000, with step 50ms
  once: true  
});
function App() {
  return (
    <Router>
    <DirectTopScroll/>
    <ScrollToTop/>
      <Navbar />
      <Switch>
        <Route exact path='/' component={Home} />
        <Route path='/about' component={About} />
        <Route path='/products' component={Products} />
        <Route path='/contact' component={Contact} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
