import React, { useRef } from 'react';
import './ContactBanner.css'
import '../../../App.css'
import { FaAngleDown } from 'react-icons/fa';
const ContactBanner = () => {
    const sectionloc = useRef(null);
    const gotoSection = () =>
        window.scrollTo({
            top: sectionloc.current.offsetTop,
            behavior: "smooth"
        });

    return (
        <>
            <div className='hero3-container' data-aos='fade-right'>
                <h1>Get in Touch with Us</h1>
                <p>"We'd love to hear from You."</p>
                <div className="arrow bounce">
                    <FaAngleDown className="ArrowDown" onClick = {gotoSection}/>
                </div>
            </div>
            <h1 ref = {sectionloc}></h1>
        </>
    );
}

export default ContactBanner;
