import React from 'react';
import './HeroSection.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';
function HeroSection({
  lightBg,
  topLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  imgStart,
  aosdata,
  target
}) {
  return (
    <>
      <div
        className={lightBg ? 'home__hero-section' : 'home__hero-section darkBg'}
        data-aos={aosdata === 'fade-left' ? 'fade-left' : 'fade-right'}>
        <div className='container'>
          <div
            className='row home__hero-row'
            style={{
              display: 'flex',
              flexDirection: imgStart === 'start' ? 'row-reverse' : 'row'
            }}
          >
            <div className='col'>
              <div className='home__hero-text-wrapper'>
                <div className='top-line'>{topLine}</div>
                <h1 className={lightText ? 'heading' : 'heading dark'}>
                  {headline}
                </h1>
                <p
                  className={
                    lightTextDesc
                      ? 'home__hero-subtitle'
                      : 'home__hero-subtitle dark'
                  }
                >
                  {description}
                </p>
                <Link to={target === 'about' ? '/about' : target === 'products' ? '/products' : '/contact'}>
                  <Button buttonSize='btn--wide' buttonColor='blue'>
                    {buttonLabel}
                  </Button>
                </Link>
              </div>
            </div>
            <div className='col'>
              <div className='home__hero-img-wrapper'>
                <img src={img} alt={alt} className='home__hero-img' data-aos = 'zoom-out'/>
                {/* <Carousel>
                  <Carousel.Item interval={500}>
                    <img
                      className="home__hero-img"
                      src="images/prod1.jpeg"
                      alt="First slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item interval={500}>
                    <img
                      className="home__hero-img"
                      src="images/prod2.jpeg"
                      alt="Second slide"
                    />
                  </Carousel.Item>
                  <Carousel.Item interval={500}>
                    <img
                      className="home__hero-img"
                      src="images/prod3.jpeg"
                      alt="Third slide"
                    />
                  </Carousel.Item>
                </Carousel> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
