import React from 'react';
import BannerSection from './ProductBanner.js';
import CategoryToggler from './CategoryToggler';

function Products() {
  return (
    <>
    <BannerSection/>
    <CategoryToggler/> 
    </>
  );
}

export default Products;
