import React, { useEffect, useState } from "react";
import { useWindowScroll } from "react-use";
import {FaAngleUp} from 'react-icons/fa';
const DirectTopScroll = () => {
    const { y: pageYOffset } = useWindowScroll();
    const [visible, setVisiblity] = useState(false);

    useEffect(() => {
        if (pageYOffset > 400) {
            setVisiblity(true);
        } else {
            setVisiblity(false);
        }
    }, [pageYOffset]);

    const directTopScroll = () => window.scrollTo({ top: 0, behavior: "smooth" });

    if (!visible) {
        return false;
    }

    return (
        <div
            className="scroll-to-top cursor-pointer text-center"
            onClick={directTopScroll}
        >
            <FaAngleUp className = "faangle"/>
        </div>
    );
};

export default DirectTopScroll;