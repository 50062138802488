import React,{useRef} from 'react';
import '../../../App.css';
import './ImageBanner.css';
import {FaAngleDown} from 'react-icons/fa';
function BannerSection() {
  const sectionloc = useRef(null);

  const gotoSection = () =>
      window.scrollTo({
          top: sectionloc.current.offsetTop,
          behavior: "smooth"
      });
  return (
    <>
    <div className='hero1-container' data-aos='fade-right'>
      <h1>About Us</h1>
      <p>"It is not just Zippers.We're Known for the Trust we Build."</p>
      <div className="arrow bounce">
        <FaAngleDown className = "ArrowDown" onClick = {gotoSection}/>
    </div>
    </div>
    <h1 ref = {sectionloc}></h1>
     </>
  );
}

export default BannerSection;