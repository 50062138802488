export default [
  [
    {
      imgpath: 'images/CFC No 3 Zip Roll(1).jpeg',
      title: 'CFC No 3 Zip Roll'
    },
    {
      imgpath: 'images/CFC No 3 Zip Roll(2).jpeg',
      title: 'CFC No 3 Zip Roll'
    },
    {
      imgpath: 'images/CFC No 5 Zip Roll(1).jpeg',
      title: 'CFC No 5 Zip Roll'
    },
    {
      imgpath: 'images/CFC No 5 Zip Roll(2).jpeg',
      title: 'CFC No 5 Zip Roll'
    },
    {
      imgpath: 'images/CFC No 5 Zip Roll(3).jpeg',
      title: 'CFC No 5 Zip Roll'
    },
    {
      imgpath: 'images/CFC No 8 Zip Roll.jpeg',
      title: 'CFC No 8 Zip Roll'
    },
    {
      imgpath: 'images/CFC No 10 Zip Roll.jpeg',
      title: 'CFC No 10 Zip Roll'
    },
    {
      imgpath: 'images/CFC Zipper No 3(1).jpeg',
      title: 'CFC Zipper No 3'
    },
    {
      imgpath: 'images/CFC Zipper No 3(2).jpeg',
      title: 'CFC Zipper No 3'
    },
    {
      imgpath: 'images/CFC Zipper No 3(3).jpeg',
      title: 'CFC Zipper No 3'
    },
    {
      imgpath: 'images/CFC Zipper No 3(4).jpeg',
      title: 'CFC Zipper No 3'
    },
    {
      imgpath: 'images/CFC Zipper No 3(5).jpeg',
      title: 'CFC Zipper No 3'
    },
    {
      imgpath: 'images/CFC Zipper No 3(6).jpeg',
      title: 'CFC Zipper No 3'
    },
    {
      imgpath: 'images/CFC Zipper No 5(1).jpeg',
      title: 'CFC Zipper No 5'
    },
    {
      imgpath: 'images/CFC Zipper No 5(2).jpeg',
      title: 'CFC Zipper No 5'
    },
    {
      imgpath: 'images/CFC Zipper No 5(3).jpeg',
      title: 'CFC Zipper No 5'
    },
    {
      imgpath: 'images/CFC Zipper No 5(4).jpeg',
      title: 'CFC Zipper No 5'
    },
    {
      imgpath: 'images/CFC Zipper No 5(5).jpeg',
      title: 'CFC Zipper No 5'
    }
  ],
  [
    {
      imgpath: 'images/Metal Zipper No 3 Black Oxidised Finish.jpeg',
      title: 'Metal Zipper No 3 Black Oxidised Finish'
    },
    {
      imgpath: 'images/Metal Zipper No 3 Golden Finish.jpeg',
      title: 'Metal Zipper No 3 Golden Finish'
    },
    {
      imgpath: 'images/Metal Zipper No 3 Oxidised Finish.jpeg',
      title: 'Metal Zipper No 3 Oxidised Finish'
    },
    {
      imgpath: 'images/Metal Zipper No 3 Silver Oxidised Finish.jpeg',
      title: 'Metal Zipper No 3 Silver Oxidised Finish'
    },
    {
      imgpath: 'images/Metal Zipper No 4 Golden Finish.jpeg',
      title: 'Metal Zipper No 4 Golden Finish'
    },
    {
      imgpath: 'images/Metal Zipper No 4 Oxidised Finish(1).jpeg',
      title: 'Metal Zipper No 4 Oxidised Finish'
    },
    {
      imgpath: 'images/Metal Zipper No 4 Oxidised Finish(2).jpeg',
      title: 'Metal Zipper No 4 Oxidised Finish'
    },
    {
      imgpath: 'images/Metal Zipper No 4.5 Golden Finish.jpeg',
      title: 'Metal Zipper No 4.5 Golden Finish'
    },
    {
      imgpath: 'images/Metal Zipper No 4.5 Silver Oxidised Finish.jpeg',
      title: 'Metal Zipper No 4.5 Silver Oxidised Finish'
    },
    {
      imgpath: 'images/Metal Zipper No 5 Silver Finish.jpeg',
      title: 'Metal Zipper No 5 Silver Finish'
    },
    {
      imgpath: 'images/Metal Zipper No 5 Silver Oxidised Finish.jpeg',
      title: 'Metal Zipper No 5 Silver Oxidised Finish'
    }
  ],
  [
    {
      imgpath: 'images/Fiber Zipper No 5(1).jpeg',
      title: 'Fiber Zipper No 5'
    },
    {
      imgpath: 'images/Fiber Zipper No 5(2).jpeg',
      title: 'Fiber Zipper No 5'
    },
    {
      imgpath: 'images/Fiber Zipper No 5(3).jpeg',
      title: 'Fiber Zipper No 5'
    },
    {
      imgpath: 'images/Fiber Zipper No 5(4).jpeg',
      title: 'Fiber Zipper No 5'
    },
    {
      imgpath: 'images/Fiber Zipper No 8(1).jpeg',
      title: 'Fiber Zipper No 8'
    },
    {
      imgpath: 'images/Fiber Zipper No 8(2).jpeg',
      title: 'Fiber Zipper No 8'
    },
    {
      imgpath: 'images/Fiber Zipper No 8(3).jpeg',
      title: 'Fiber Zipper No 8'
    },
    {
      imgpath: 'images/Fiber Zipper No 8(4).jpeg',
      title: 'Fiber Zipper No 8'
    }
  ],
  [
    {
      imgpath: 'images/Invisible Zipper No 3 Knitted(1).jpeg',
      title: 'Invisible Zipper No 3 Knitted'
    },
    {
      imgpath: 'images/Invisible Zipper No 3 Knitted(2).jpeg',
      title: 'Invisible Zipper No 3 Knitted'
    },
    {
      imgpath: 'images/Invisible Zipper No 3 Knitted(3).jpeg',
      title: 'Invisible Zipper No 3 Knitted'
    },
    {
      imgpath: 'images/Invisible Zipper No 3 Knitted(4).jpeg',
      title: 'Invisible Zipper No 3 Knitted'
    },
    {
      imgpath: 'images/Invisible Zipper No 3 Knitted(5).jpeg',
      title: 'Invisible Zipper No 3 Knitted'
    },
    {
      imgpath: 'images/Invisible Zipper No 3 Knitted(6).jpeg',
      title: 'Invisible Zipper No 3 Knitted'
    },
    {
      imgpath: 'images/Invisible Zipper Woven(1).jpeg',
      title: 'Invisible Zipper Woven'
    },
    {
      imgpath: 'images/Invisible Zipper Woven(2).jpeg',
      title: 'Invisible Zipper Woven'
    }
  ],
  [
    {
      imgpath: 'images/LFC Zipper(1).jpeg',
      title: 'LFC Zipper'
    },
    {
      imgpath: 'images/LFC Zipper(2).jpeg',
      title: 'LFC Zipper'
    }
  ],
  [
    {
      imgpath: 'images/Sliders(1).jpeg',
      title: 'Sliders No 1'
    },
    {
      imgpath: 'images/Sliders(2).jpeg',
      title: 'Sliders No 2'
    },
    {
      imgpath: 'images/Sliders(3).jpeg',
      title: 'Sliders No 3'
    }
  ]
];
