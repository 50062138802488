export const homeObjOne = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Established Since 1996',
  headline: "We've been serving for over 25 years",
  description:
    'Dayal Zippers, established in 1996, is located at Mumbai in Maharashtra. Having first started as a trading company, over the next 25 years it has become one of the largest zipper manufacturers in Mumbai with over thousands of zippers and zipper rolls being manufactured daily. Currently it boasts of world class infrastructure and modern machinery which is able to manufacture quality products in the quickest possible time.',
  buttonLabel: 'Know Us More',
  imgStart: '',
  img: 'images/bond.jpeg',
  alt: 'Credit Card',
  aosdata: 'fade-right',
  target: 'about'
};

export const homeObjTwo = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'We Produce Quality',
  headline: 'Why Choose Us?',
  description:
    'One of the best parts of us is that you will be able to find all the desired types, quality, and range of zippers here under one roof. Along with this, Dayal Zippers focuses on client satisfaction along with providing the best products and hence we have the best technical experts and dedicated quality control team working on each stage of production',
  buttonLabel: 'Connect Us',
  imgStart: '',
  img: 'images/whyus.jpeg',
  alt: 'Vault',
  aosdata: 'fade-right',
  target: 'contact'
};

export const homeObjThree = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'View Our Products',
  headline: 'Know our Wide Variety of Products',
  description:
    "Dayal Zippers has a daily capacity of manufacturing over thousands of zippers and zipper rolls. We manufacture a wide range of products that include nylon zippers, metallic zippers, fiber zippers as well as zipper rolls according to the customer’s requirements. We also deal in sliders and can provide the same based on the consumer needs.",
  buttonLabel: 'View Products',
  imgStart: 'start',
  img: 'images/product3.jpeg',
  alt: 'Vault',
  aosdata: 'fade-right',
  target: 'products'
};

// export const homeObjFour = {
//   lightBg: false,
//   lightText: true,
//   lightTextDesc: true,
//   topLine: 'DATA ANALYTICS',
//   headline: 'Every transaction is stored on our secure cloud database',
//   description:
//     'Never ever have to worry again about saved reciepts. We store your data, so you can access it anytime.',
//   buttonLabel: 'Sign Up Now',
//   imgStart: 'start',
//   img: 'images/svg-8.svg',
//   alt: 'Vault',
// };
