import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import './CategoryToggler.css';
import Data from './Data';
import ProductCategoryView from './ProductCategoryView';
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: 50,
    },
}));


export const CategoryToggler = () => {
    const [active, setActive] = useState("nylon");
    const classes = useStyles();
    return (
        <>
        <div className={classes.root} data-aos="fade-right">
            <h1 className='CategoryStyling'>Product Categories</h1>
            <Grid container spacing={3}>
                <Grid item xs={6} sm={4} md={2}>
                    <button
                        className={active === 'nylon' ? 'buttonstyling-active' : 'buttonstyling'}
                        onClick={() => setActive('nylon')}>NYLON ZIPPERS</button>
                </Grid>
                <Grid item xs={6} sm={4} md={2}>
                    <button
                        className={active === 'plastic' ? 'buttonstyling-active' : 'buttonstyling'}
                        onClick={() => setActive('plastic')}>FIBER ZIPPERS</button>
                </Grid>
                <Grid item xs={6} sm={4} md={2}>
                    <button
                        className={active === 'metal' ? 'buttonstyling-active' : 'buttonstyling'}
                        onClick={() => setActive('metal')}>METAL ZIPPERS</button>
                </Grid>
                <Grid item xs={6} sm={4} md={2}>
                    <button
                        className={active === 'invisible' ? 'buttonstyling-active' : 'buttonstyling'}
                        onClick={() => setActive('invisible')}>INVISIBLE ZIPPERS</button>
                </Grid>
                <Grid item xs={6} sm={4} md={2}>
                    <button
                        className={active === 'LCS' ? 'buttonstyling-active' : 'buttonstyling'}
                        onClick={() => setActive('LCS')}>LFC ZIPPERS</button>
                </Grid>
                <Grid item xs={6} sm={4} md={2}>
                    <button
                        className={active === 'styled' ? 'buttonstyling-active' : 'buttonstyling'}
                        onClick={() => setActive('styled')}>SLIDERS</button>
                </Grid>
            </Grid>
        </div>
        <div>
        {active === 'nylon' && <ProductCategoryView data = {Data} tiledata = {0}/>}
        {active === 'plastic' && <ProductCategoryView data = {Data} tiledata = {2}/>}
        {active === 'metal' && <ProductCategoryView data = {Data} tiledata = {1}/>}
        {active === 'invisible' && <ProductCategoryView data = {Data} tiledata = {3}/>}
        {active === 'LCS' && <ProductCategoryView data = {Data} tiledata = {4}/>}
        {active === 'styled' && <ProductCategoryView data = {Data} tiledata = {5}/>}
        </div>
        </>
    )
}

export default CategoryToggler;